@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Jost";
  src: local("Jost"),
   url('./assets/fonts/Jost-Medium.woff') format("woff");

  }


*{
  font-family: 'Jost', sans-serif;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-none {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}





@-webkit-keyframes slide-left {
0% { transform: translateX(0%) }
100% { transform: translateX(-100%) }
}
@-moz-keyframes slide-left {
0% { transform: translateX(0%) }
100% { transform: translateX(-100%) }
}
@-o-keyframes slide-left {
0% { transform: translateX(0%) }
100% { transform: translateX(-100%) }
}
@keyframes slide-left {
0% { transform: translateX(0%) }
100% { transform: translateX(-100%) }
}

.slide-left {
  -webkit-animation: slide-left 300ms ease;
  -moz-animation: slide-left 300ms ease;
  -o-animation: slide-left 300ms ease;
  animation: slide-left 300ms ease;
}